<template>
  <b-container class="container-rda siderbar" style="max-width: 100%">
    <b-row class="height">
      <sidebar-admin />
      <b-col class="content">
        <b-row>
          <b-col>
            <b-row>
              <b-col>
                <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="margin-top: 20px">
          <b-col>
            <b-container fluid>
              <b-row
                class="header-list-teacher"
                v-if="attendances.length > 0 && requestSent"
              >
                <b-col>
                  <p class="bold">Aluno</p>
                </b-col>
                <b-col>
                  <p class="bold">Contratantes</p>
                </b-col>
                <b-col>
                  <p class="bold">Preferência de Notificações</p>
                </b-col>
              </b-row>
            </b-container>

            <b-container fluid>
              <b-row
                align-v="center"
                class="list-teacher"
                v-for="attendance in attendances"
                v-bind:key="attendance.id"
              >
                <b-col>
                  <p>{{ attendance.student_name }}</p>
                </b-col>

                <b-col>
                  <p>{{ attendance.responsible_name }}</p>
                </b-col>

                <b-col>
                  <p>{{ attendance.option }}</p>
                </b-col>
              </b-row>

              <b-row
                align-v="center"
                class="list-teacher"
                v-if="attendances.length <= 0 && requestSent"
              >
                <b-col>Nenhum resultado encontrado</b-col>
              </b-row>

              <b-row align-v="center" class="list-teacher" v-if="!requestSent">
                <b-col>Carregando</b-col>
              </b-row>

              <b-row
                class="align-center"
                v-else-if="count > perPage && requestSent"
              >
                <b-pagination
                  v-model="page"
                  :per-page="perPage"
                  :total-rows="count"
                  @change="changePage"
                  aria-controls="teachersList"
                  last-text
                  first-text
                >
                  <template #prev-text>
                    <LeftArrowIcon class="left" />
                  </template>
                  <template #next-text>
                    <LeftArrowIcon />
                  </template>
                </b-pagination>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import httpRda from "../../../http-rda";
import SidebarAdmin from "../../../components/SidebarAdmin.vue";

export default {
  components: {
    SidebarAdmin,
  },

  data() {
    return {
      name: "vídeos",
      rdaUnit: process.env.VUE_APP_RDA_UNIT,
      breadcrumb: [
        {
          text: "Administração",
          href: "/administracao",
        },
        {
          text: "Notificações de Faltas",
          href: "/administracao/notificacoes-de-faltas",
        },
      ],
      dictionary: [
        { value: "Ser notificado a cada falta", key: "each_attendance" },
        {
          value: "Ser notificado a cada três faltas",
          key: "interval_attendance",
        },
        { value: "Não ser notificado", key: "none" },
      ],
      requestSent: false,
      attendances: [],
      count: 0,
      page: 1,
      perPage: 20,
    };
  },

  methods: {
    changePage(page) {
      this.page = page;
      this.getAttendances();
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    async getAttendances() {
      this.requestSent = false;
      this.attendances = [];

      try {
        const response = await httpRda.get("/attendance-notifications", {
          params: {
            page: this.page,
            perPage: this.perPage,
          },
        });

        const result = response.data;

        this.requestSent = true;
        this.attendances = result.data.length > 0 ? result.data : [];

        this.attendances = this.attendances.map((attendance) => {
          return {
            ...attendance,
            option: this.dictionary.find(
              (item) => item.key === attendance.option
            ).value,
          };
        });

        this.count = result.count;
        this.page = result.page;
        this.perPage = result.perPage;

        this.requestSent = true;
      } catch (error) {
        this.requestSent = true;
        this.attendances = [];
        console.log(error);
      }
    },
  },

  beforeMount() {
    if (this.rdaUnit !== "BELO_HORIZONTE") {
      this.$router.push({ name: "indicadores" });
    }
  },
  mounted() {
    this.getAttendances();
  },
};
</script>

<style></style>
